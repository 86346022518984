import {
  Deferred,
  MethodInfo,
  NextUnaryFn,
  RpcError,
  RpcInterceptor,
  RpcMetadata,
  RpcOptions,
  RpcStatus,
  UnaryCall,
} from '@protobuf-ts/runtime-rpc';
import {AuthStorage, AuthStorageKeys} from './Auth';
import {DeferredAuth0Client} from './DeferredAuth0Client';
import {parseCiamControlValues} from './parseCiamControlValues';
import {
  deletePsatFromCookie,
  getPsatFromCookie,
  setPsatCookie,
} from './psatCookieUtils';

// Proto ts implementation uses the string representation of the RpcStatus code.
enum GRPCAuthCodes {
  UNAUTHENTICATED = 'UNAUTHENTICATED',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
}

// Supported error codes sent as headers from CIAM's bff client library.
// These values must match the strings returned by `grpc/codes.Code.String()`
enum CIAMHeaderErrorCode {
  UNAUTHENTICATED = 'Unauthenticated',
  PERMISSION_DENIED = 'PermissionDenied',
}

export enum CiamAuthErrorCode {
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  COOKIES_DISABLED = 'COOKIES_DISABLED',
}

interface WebViewAuthBridge {
  // Interface for iOS WebKit WebView
  webkit?: {
    messageHandlers: {
      VerilyAuthBridge?: {
        postMessage: (request: string) => Promise<string>;
      };
    };
  };
  // Interface for Android WebView
  VerilyAuthBridge?: {
    getAccessToken: () => string;
    getIdToken: () => string;
    loginWithRedirect?: (url: string) => string;
    getProxyAuthorizationIdToken: () => string;
  };
}

function getExtraQueryParams(headers: RpcMetadata): Record<string, string> {
  try {
    const entries = ((headers['ciam-aqp'] ?? []) as string[]).map(
      keyvalue => keyvalue.split('=') as [string, string]
    );
    const extraQueryParams: Record<string, string> = {};
    for (const [key, val] of entries) {
      extraQueryParams[key] = val;
    }
    return extraQueryParams;
  } catch {
    return {};
  }
}

// must contain domain or appName or both
type DomainAndOrAppName =
  | {
      domain: string;
      appName: null;
    }
  | {
      domain: null;
      appName: string; // TODO: replace with ApplicationSuite enu,
    }
  | {
      domain: string;
      appName: string;
    };

class AuthUnaryInterceptor implements RpcInterceptor {
  private client: DeferredAuth0Client;
  private storage: AuthStorage;
  readonly config: DomainAndOrAppName;

  constructor(
    client: DeferredAuth0Client,
    config: DomainAndOrAppName,
    storage: AuthStorage
  ) {
    this.client = client;
    this.config = config;
    this.storage = storage;
  }

  /**
   * Uses the native mobile Auth0 SDK to login and perform redirect.
   * @returns boolean whether the native redirect was handled.
   */
  private async initiateNativeLogin(): Promise<boolean | undefined> {
    const bridge = window as WebViewAuthBridge;
    // iOS WebView returns a Promise.
    if (bridge?.webkit?.messageHandlers?.VerilyAuthBridge) {
      return bridge.webkit.messageHandlers.VerilyAuthBridge.postMessage(
        'loginWithRedirect'
      ).then(() => true);
    }
    // Android WebView returns synchronously.
    if (bridge.VerilyAuthBridge && bridge.VerilyAuthBridge.loginWithRedirect) {
      bridge.VerilyAuthBridge.loginWithRedirect(window.location.href);
      return Promise.resolve(true);
    }
    return Promise.resolve(false);
  }

  /**
   * Uses Auth0Client to login with a redirect.
   * Utilizes the configured authorizationParams.
   */
  private async initiateLogin(headers: RpcMetadata): Promise<void> {
    this.storage.storeAuthItem(
      AuthStorageKeys.RETURN_ROUTE,
      window.location.href
    );
    this.initiateNativeLogin()
      .then(didHandleNatively => {
        if (didHandleNatively) {
          return;
        }
        return this.client.loginWithRedirect(getExtraQueryParams(headers));
      })
      .catch(() => {
        return this.client.loginWithRedirect(getExtraQueryParams(headers));
      });
  }

  /**
   * Determines whether the user should log in
   * @param code - RPC status code
   * @returns tuple: [whether the user needs to log in, error]
   */
  private async handleRpcCode(
    {code}: RpcStatus | RpcError,
    controlValues: Record<string, string>
  ): Promise<[boolean, CiamAuthErrorCode | null]> {
    if (controlValues['error-code'] != null) {
      switch (controlValues['error-code']) {
        case CIAMHeaderErrorCode.UNAUTHENTICATED:
          code = GRPCAuthCodes.UNAUTHENTICATED;
          break;
        case CIAMHeaderErrorCode.PERMISSION_DENIED:
          code = GRPCAuthCodes.PERMISSION_DENIED;
          break;
      }
    }
    switch (code) {
      case GRPCAuthCodes.UNAUTHENTICATED: {
        if (navigator.cookieEnabled) {
          return [true, null];
        } else {
          return [false, CiamAuthErrorCode.COOKIES_DISABLED];
        }
      }
      case GRPCAuthCodes.PERMISSION_DENIED:
        return [false, CiamAuthErrorCode.PERMISSION_DENIED];
      default:
        return [false, null];
    }
  }

  /**
   * Gets token if present. Otherwise returns null.
   * We do not force login as the auth flow should only be initiated when CIAM request so.
   * @returns a promise that resolves to a token or rejects with an error.
   */
  private async getAccessToken(): Promise<string | undefined> {
    // Check for an access token provided by a JS bridge.
    const remoteToken = await this.getRemoteAccessToken();
    if (remoteToken) {
      return remoteToken;
    }

    try {
      // This is done to wait for method to throw error so it can be handled here.
      const token = await this.client.getTokenSilently();
      return token;
    } catch (error) {
      return undefined;
    }
  }
  /**
   * Returns the access token from a Javascript bridge if present. Otherwise returns null.
   * @returns a promise that resolves to a token string or undefined. Will never reject.
   */
  private async getRemoteAccessToken(): Promise<string | undefined> {
    const bridge = window as WebViewAuthBridge;
    // iOS WebView returns a Promise.
    if (bridge?.webkit?.messageHandlers?.VerilyAuthBridge) {
      return bridge.webkit.messageHandlers.VerilyAuthBridge.postMessage(
        'getAccessToken'
      );
    }
    // Android WebView returns synchronously.
    if (bridge.VerilyAuthBridge) {
      return Promise.resolve(bridge.VerilyAuthBridge.getAccessToken());
    }
    return Promise.resolve(undefined);
  }

  private async getIdToken(): Promise<string | undefined> {
    // Check for an ID token provided by a JS bridge.
    const remoteToken = await this.getRemoteIdToken();
    if (remoteToken) {
      return remoteToken;
    }

    const claims = await this.client.getIdTokenClaims();
    return claims?.__raw;
  }

  /**
   * Returns the Id token from a Javascript bridge if present. Otherwise returns null.
   * @returns a promise that resolves to a token string or undefined. Will never reject.
   */
  private async getRemoteIdToken(): Promise<string | undefined> {
    const bridge = window as WebViewAuthBridge;
    // iOS WebView returns a Promise.
    if (bridge?.webkit?.messageHandlers?.VerilyAuthBridge) {
      return bridge.webkit.messageHandlers.VerilyAuthBridge.postMessage(
        'getIdToken'
      );
    }
    // Android WebView returns synchronously.
    if (bridge.VerilyAuthBridge) {
      return Promise.resolve(bridge.VerilyAuthBridge.getIdToken());
    }
    return Promise.resolve(undefined);
  }

  /**
   * Returns the id token for proxy-authorization (i.e. Identity Aware Proxy) from a Javascript bridge if present.
   * Otherwise returns null. @returns a promise that resolves to a token string or undefined. Will never reject.
   */
  private async getRemoteProxyAuthorizationIdToken(): Promise<
    string | undefined
  > {
    const bridge = window as WebViewAuthBridge;
    // iOS WebView returns a Promise.
    if (bridge?.webkit?.messageHandlers?.VerilyAuthBridge) {
      return bridge.webkit.messageHandlers.VerilyAuthBridge.postMessage(
        'getProxyAuthorizationIdToken'
      );
    }
    // Android WebView returns synchronously.
    if (
      bridge.VerilyAuthBridge &&
      bridge.VerilyAuthBridge.getProxyAuthorizationIdToken
    ) {
      return Promise.resolve(
        bridge.VerilyAuthBridge.getProxyAuthorizationIdToken()
      );
    }
    return Promise.resolve(undefined);
  }

  /**
   * Intercepts grpc calls to add Authorization metadata.
   * If call is returned unauthorized, then forces a login with Auth0,
   * stores token and retries request.
   */
  interceptUnary(
    next: NextUnaryFn,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    method: MethodInfo<any, any>,
    input: object,
    options: RpcOptions
  ): UnaryCall<object, object> {
    // Implementation based on: https://github.com/timostamm/protobuf-ts/issues/580
    const defHeader = new Deferred<RpcMetadata>();
    const defMessage = new Deferred<object>();
    const defStatus = new Deferred<RpcStatus>();
    const defTrailer = new Deferred<RpcMetadata>();

    const reject = (error: unknown) => {
      defHeader.rejectPending(error);
      defMessage.rejectPending(error);
      defStatus.rejectPending(error);
      defTrailer.rejectPending(error);
    };

    if (!options.meta) {
      options.meta = {};
    }

    // We have to wrap this in an async function so we can use await.
    // This is because getTokenSilently is async, and the RPCInterceptor signature
    // does not allow us to return a promise
    (async () => {
      try {
        // Check if PSAT token is present in the cookie
        const psat = getPsatFromCookie();
        const accessToken = await this.getAccessToken();
        const idToken = await this.getIdToken();
        const proxyAuthToken = await this.getRemoteProxyAuthorizationIdToken();

        // Set CIAM auth headers.
        if (accessToken && psat) {
          options.meta!.Authorization = `Bearer ${accessToken}`;
          options.meta!['ciam-additional-tokens'] = `Bearer ${psat}`;
        } else {
          if (psat) {
            options.meta!.Authorization = `Bearer ${psat}`;
          }
          if (accessToken) {
            options.meta!.Authorization = `Bearer ${accessToken}`;
          }
        }

        if (idToken) {
          options.meta!.IdToken = idToken;
        }

        if (this.config.domain === null) {
          options.meta!.AuthAppName = this.config.appName;
        }

        // Include a Proxy-Authorization header if present (for IAP).
        if (proxyAuthToken && proxyAuthToken != '') {
          options.meta!['Proxy-Authorization'] = `Bearer ${proxyAuthToken}`;
        }

        // perform the actual call
        const result = next(method, input, options);

        const controlValues = parseCiamControlValues(await result.headers);
        await this.saveAuthConfig(controlValues);

        const [shouldLogIn, rejectCode] = await this.handleRpcCode(
          await result.status,
          controlValues
        );
        if (rejectCode === null) {
          defHeader.resolve(result.headers);
          defMessage.resolve(result.response);
          defStatus.resolve(result.status);
          defTrailer.resolve(result.trailers);
        } else {
          reject({code: rejectCode});
        }
        shouldLogIn && (await this.initiateLogin(await result.headers));
      } catch (error) {
        const rpcError = error as RpcError;
        const controlValues = parseCiamControlValues(rpcError.meta);
        await this.saveAuthConfig(controlValues);

        const [shouldLogIn, wrappedErrorCode] = await this.handleRpcCode(
          rpcError,
          controlValues
        );
        shouldLogIn && (await this.initiateLogin(rpcError.meta));
        reject(wrappedErrorCode ?? error);
      }
    })();

    return new UnaryCall(
      method,
      options.meta,
      input,
      defHeader.promise,
      defMessage.promise,
      defStatus.promise,
      defTrailer.promise
    );
  }

  private async saveAuthConfig(controlValues: Record<string, string>) {
    const domain = controlValues['domain'];
    const clientId = controlValues['client-id'];
    const audience = controlValues['audience'];
    if (domain != null) {
      this.config.domain = domain;
      if (clientId != null && audience != null) {
        await this.client.init(domain, clientId, {
          audience,
        });
      }
    }
    // Store PSAT in cookie if it is present in the header.
    const psat = controlValues['psat'];
    const psatExpires = controlValues['psat_expiry'];
    if (psat != null && psatExpires != null) {
      setPsatCookie(psat, psatExpires);
    }

    if (controlValues['purge_psat'] === 'true') {
      deletePsatFromCookie();
    }
  }
}

export {AuthUnaryInterceptor, GRPCAuthCodes};
